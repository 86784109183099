import { defineStore } from 'pinia';

export const useCartStore = defineStore('cart', {

    state: () => ({
        cart: null as any,

        fastestDelivery: true,

        paymentTypes: [],
        paymentTypesV2: [],
        showOtherPayments: false,
        paymentMethods: [],
        selectedPaymentMethod: null,
        selectedPaymentType: null,

        promocode: null as any,
        promocodeError: null as any,

        writeOff: false as boolean,

        comment: null as any,

        timeSlotsResponse: null as any,
        slots: [] as any[],
        selectedSlot: null as any,
        selectedSlotDate: null as any,
        slotDates: [] as any[],

        nearestTimeResponse: null as any,

        upsales: null as any,

        firstLoading: true,
        loading: false,
        loadingMethods: false,
        loadingSlots: false,
        loadingNearestTime: false,
        orderCreating: false,
    }),

    getters: {
        finalTotal(state) {
            if (state.cart == null) {
                return 0;
            }

            let total = state.cart.total;

            if (state.writeOff) {
                total -= state.cart.loyalty?.write_off_amount;
            }

            return total;
        },

        finalDiscount(state) {
            if (state.cart == null) {
                return 0;
            }

            let value = state.cart.total_no_discount - state.cart.total;

            if (state.writeOff) {
                value += state.cart.loyalty?.write_off_amount;
            }

            return value;
        },

        finalCashback(state) {
            if (state.cart == null) {
                return 0;
            }

            if (state.writeOff) {
                return state.cart.loyalty?.write_off_cashback ?? 0;
            }

            return state.cart.loyalty?.full_cashback ?? 0;
        },

        paymentTypesV2Showed(state) {
            if (state.showOtherPayments) {
                return this.paymentTypesV2;
            }

            let list = this.paymentTypesV2.filter((el: any) => el.key == 'yookassa.sbp');

            if (list.length == 0) {
                return this.paymentTypesV2;
            }

            return list;
        },

        productsForCartButton(state) {
            return [...Object.values(state.cart.cart_combos), ...Object.values(state.cart.cart_products)].splice(0, 3);
        },

        count(state) {
            let count = 0;
            (state.cart?.cart_combos ?? []).forEach((el: any) => {
                count += el.quantity;
            });

            (state.cart?.cart_products ?? []).forEach((el: any) => {
                count += el.quantity;
            });

            return count;
        },

        allProducts(state) {
            return [...Object.values(state.cart?.cart_combos ?? []), ...Object.values(state.cart?.cart_products ?? [])];
        },

        isEmpty(state) {
            return !state.cart || (state.cart.cart_products.length + state.cart.cart_combos.length) == 0;
        },

        cartButtonError(state) {
            if (state.cart == null || !state.cart.errors || state.cart.errors.length == 0) {
                return null;
            }

            let filtered = state.cart.errors.filter((el: any) => el.for_cart_button);

            if (filtered.length > 0) {
                return filtered[0];
            }

            return null;
        },

        cartBlockErrors(state) {
            if (state.cart == null || !state.cart.errors || state.cart.errors.length == 0) {
                return [];
            }

            let filtered = state.cart.errors.filter((el: any) => el.block);

            return filtered;
        },

        filteredUpsales(state) {
            if (!state.upsales || !state.cart?.cart_products) {
                return null;
            }

            let products = state.cart.cart_products.map((el: any) => el.product.id);

            let upsales = state.upsales.filter((el: any) => !products.includes(el.product.id));
            return upsales;
        }
    },

    actions: {
        // async fetchMethods() {
        //     const authStore = useAuthStore();

        //     if (authStore.user.phone == '79162605568' || authStore.user.phone == '79651888982' || authStore.user.phone == '79162971000') {
        //         return await this.fetchMethodsV2();
        //     }

        //     this.loadingMethods = true;

        //     const [data, typesData] = await Promise.all([
        //         useDFetch("payment-methods"),
        //         useDFetch("cart/payment-types")
        //     ]);

        //     this.paymentMethods = data as [];
        //     this.paymentTypes = typesData as [];

        //     if (this.selectedPaymentMethod == null && this.selectedPaymentType == null) {
        //         if (this.paymentMethods.length > 0) {
        //             this.selectedPaymentMethod = this.paymentMethods[0];
        //             this.selectedPaymentType = null;
        //         } else if (this.paymentTypes.length > 0) {
        //             this.selectedPaymentType = this.paymentTypes[0];
        //             this.selectedPaymentMethod = null;
        //         }
        //     }

        //     this.loadingMethods = false;
        // },

        async fetchMethods() {
            this.loadingMethods = true;

            const [data, typesData] = await Promise.all([
                useDFetch("payment-methods"),
                useDFetch("v2/cart/payment-types")
            ]);

            let res = typesData as any[];

            if (res.length > 0 && typeof res[0] === "string") {
                this.paymentMethods = data as [];
                this.paymentTypes = typesData as [];
                this.paymentTypesV2 = [];

                if (this.selectedPaymentMethod == null && this.selectedPaymentType == null) {
                    if (this.paymentMethods.length > 0) {
                        this.selectedPaymentMethod = this.paymentMethods[0];
                        this.selectedPaymentType = null;
                    } else if (this.paymentTypes.length > 0) {
                        this.selectedPaymentType = this.paymentTypes[0];
                        this.selectedPaymentMethod = null;
                    }
                }
            } else {
                this.paymentTypesV2 = typesData as [];
                this.selectedPaymentMethod = null;

                if (this.selectedPaymentType == null) {
                    this.selectedPaymentType = this.paymentTypesV2[0]['key'];
                }
            }

            this.loadingMethods = false;
        },

        async fetchUpsales() {
            const data = await useDFetch("upsale", { tempGuest: true });

            this.upsales = data as any;
        },

        async fetchNearestTime() {
            this.loadingNearestTime = true;
            try {
                const data = await useDFetch("v2/cart/delivery-time/nearest");
                this.nearestTimeResponse = data;
            } catch (error) {
                this.nearestTimeResponse = null;
            }
            this.loadingNearestTime = false;
        },

        async fetchSlots(today: boolean = false) {

            if (today == true) {
                this.selectedSlotDate = null;
            }

            this.loadingSlots = true;
            const data = await useDFetch("v2/cart/delivery-time", {
                query: {
                    date: this.selectedSlotDate
                }
            });

            this.timeSlotsResponse = data as any;

            if (this.timeSlotsResponse) {
                this.slots = this.timeSlotsResponse.slots ?? [];
                this.slotDates = this.timeSlotsResponse.dates ?? [];

                if (this.selectedSlotDate == null && this.slotDates.length > 0) {
                    this.selectedSlotDate = this.slotDates[0];
                } else if (this.slotDates.length > 0) {
                    if (!this.slotDates.includes(this.selectedSlotDate)) {
                        this.selectedSlotDate = this.slotDates[0];
                    }
                } else {
                    this.selectedSlotDate = null;
                }

                var newSelected = null;
                if (this.selectedSlot != null) {
                    this.slots.forEach(el => {
                        if (this.selectedSlot.datetime == el.datetime) {
                            newSelected = el;
                        }
                    });
                }

                if (!newSelected && this.slots.length > 0) {
                    newSelected = this.slots[0];
                }

                this.selectedSlot = newSelected;
            } else {
                this.selectedSlot = null;
            }

            this.loadingSlots = false;
        },

        async fetchServer() {
            this.loading = true;
            let waitForCoupon = this.promocode != null && this.cart.coupon?.code == null;

            try {
                const { data } = await useApiFetch("cart", {
                    tempGuest: true,
                    query: {
                        coupon: this.promocode,
                        sticky_coupon: true,
                    },
                    cache: "no-cache",
                });
                this.cart = data.value;

                if (!this.cart?.loyalty?.write_off_amount) {
                    this.writeOff = false;
                }

                if (waitForCoupon && this.cart.coupon?.code == null) {
                    this.promocodeError = 'промокод не действителен, попробуйте другой';
                    this.promocode = null;

                    setTimeout(() => {
                        this.promocodeError = null;
                    }, 3000);
                }

            } catch (error) {
                this.cart = null;
                this.promocode = null;
            }

            this.loading = false;
        },

        async fetch(savedCoupon: any = null) {
            if (savedCoupon) {
                this.promocode = savedCoupon;
            }

            this.loading = true;
            let waitForCoupon = this.promocode != null && this.cart?.coupon?.code == null;

            try {
                const data = await useDFetch("cart", {
                    tempGuest: true,
                    query: {
                        coupon: this.promocode,
                        sticky_coupon: true,
                    },
                    cache: "no-cache",
                });
                this.cart = data;

                if (!this.cart?.loyalty?.write_off_amount) {
                    this.writeOff = false;
                }

                if (waitForCoupon && this.cart.coupon?.code == null) {
                    this.promocodeError = 'промокод не действителен, попробуйте другой';
                    this.promocode = null;

                    setTimeout(() => {
                        this.promocodeError = null;
                    }, 3000);
                }

                let couponCookie = useCookie('coupon');
                couponCookie.value = this.cart.coupon?.code;

            } catch (error) {
                this.cart = null;
                this.promocode = null;
            }

            this.firstLoading = false;
            this.loading = false;
        },

        async addToCart(product: any, modifiers: any, tabModifiers: any, reqModifiers: any) {
            this.loading = true;
            try {
                let modifierIds = [] as number[];

                Object.values(modifiers).forEach((el: any) => {
                    modifierIds.push(...el.map((el: any) => el.id));
                });

                Object.values(reqModifiers).forEach((el: any) => {
                    modifierIds.push(...el.map((el: any) => el.id));
                });

                modifierIds.push(...Object.values(tabModifiers).map((el: any) => el.id));

                const data = await useDFetch("cart/add", {
                    tempGuest: true,
                    method: "POST",
                    body: {
                        "product_id": product.id,
                        "modifier_ids": modifierIds,
                        "can_handle_quantity": true,
                        "sticky_coupon": true,
                        "coupon": this.promocode,
                    }
                });

                this.cart = data;
                this.loading = false;

                if (!this.cart?.loyalty?.write_off_amount) {
                    this.writeOff = false;
                }

                useFlocktory().updateCart(this.cart);

                if (this.cart?.popup != null) {
                    await cartPopup(this.cart?.popup);
                }

                return true;
            } catch (error) {
                console.log(error);
                this.loading = false;
                return false;
            }
        },

        async addComboToCart(comboId: any, productIds: any) {
            this.loading = true;
            try {
                const data = await useDFetch("cart/add/combo", {
                    tempGuest: true,
                    method: "POST",
                    body: {
                        "combo_id": comboId,
                        "combo_product_ids": productIds,
                        "can_handle_quantity": true,
                        "sticky_coupon": true,
                        "coupon": this.promocode,
                    }
                });

                this.cart = data;
                this.loading = false;

                if (!this.cart?.loyalty?.write_off_amount) {
                    this.writeOff = false;
                }

                useFlocktory().updateCart(this.cart);

                if (this.cart?.popup != null) {
                    await cartPopup(this.cart?.popup);
                }

                return true;
            } catch (error) {
                console.log(error);
                this.loading = false;
                return false;
            }
        },

        async addQuantityToCart(cartProduct: any) {
            this.loading = true;
            try {
                const data = await useDFetch("cart/add/quantity", {
                    tempGuest: true,
                    method: "POST",
                    body: {
                        "cart_product_id": cartProduct.id,
                        "coupon": this.promocode,
                        "can_handle_quantity": true,
                        "sticky_coupon": true,
                    }
                });

                this.cart = data;
                this.loading = false;

                if (!this.cart?.loyalty?.write_off_amount) {
                    this.writeOff = false;
                }

                useFlocktory().updateCart(this.cart);

                if (this.cart?.popup != null) {
                    await cartPopup(this.cart?.popup);
                }

                return true;
            } catch (error) {

                this.loading = false;
                return false;
            }


        },

        async deleteFromCart(cartProduct: any, soft: boolean = false, combo: boolean = false) {
            this.loading = true;
            const data = await useDFetch("cart/delete", {
                tempGuest: true,
                method: "POST",
                body: {
                    "cart_product_id": cartProduct.id,
                    "coupon": this.promocode,
                    "soft": soft,
                    "can_handle_quantity": true,
                    "combo": combo,
                    "sticky_coupon": true,
                }
            });

            this.cart = data;
            this.loading = false;

            if (!this.cart?.loyalty?.write_off_amount) {
                this.writeOff = false;
            }

            useFlocktory().updateCart(this.cart);

            if (this.cart?.popup != null) {
                await cartPopup(this.cart?.popup);
            }
        },
    },
});