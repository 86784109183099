<script setup>
const props = defineProps(['link', 'disabled', 'loading', 'light', 'text', 'preventDefault', 'type']);
const emit = defineEmits(['onTap']);
const nuxtLink = resolveComponent('NuxtLink');

const componentType = computed(() => {
    if (props.link == null) {
        if (props.type == 'submit') {
            return 'button';
        }

        return 'div';
    }

    return nuxtLink;
});

const buttonType = computed(() => {
    if (componentType.value == 'button') {
        if (props.disabled) {
            return 'button';
        }

        return props.type;
    }

    return null;
});

const onTap = (e) => {
    if (props.preventDefault === true) {
        e.preventDefault();
    }

    if (props.disabled || props.loading) {
        return;
    }

    emit('onTap');
};
</script>

<template>
    <component :is="componentType" @click="onTap" :to="link" :type="buttonType" class="d-button"
        :class="{ disabled: disabled, loading: loading, light: light, text: text }">
        <span :class="{ hide: loading }">
            <slot />
        </span>

        <div v-if="loading" class="spinner-border spinner-border-sm"></div>
    </component>
</template>