<script setup>
import { closeDialog } from 'vue3-promise-dialog';
import { useAuthStore } from '~/stores/auth';

const config = useRuntimeConfig();

const props = defineProps(['text']);
const { $bootstrap } = useNuxtApp();

const route = useRoute();

var modal = null;

onMounted(() => {
    const router = useRouter();
    var modalEl = document.getElementById('error-modal');
    modal = new $bootstrap.Modal(modalEl, {});
    modalEl.addEventListener('hidden.bs.modal', function (event) {
        closeDialog(true);
    });
    modal.show();
});

watch(() => route.fullPath, () => {
    modal.hide();
});

onBeforeUnmount(() => {
    modal.hide();
});

const close = () => {
    modal.hide();
};

const goToAddress = (delivery) => {
    close();

    useAuthStore().addressSwitcherCourierSelected = delivery;

    useRouter().push('/address');
}

</script>
<template>
    <div class="modal fade no-address-modal" id="error-modal" tabindex="-1" aria-labelledby="error-modal-label"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="title">Укажите адрес</div>
                    <div class="subtitle">Так мы сможем подобрать актуальное меню для вас</div>
                    <div>
                        <DButton @on-tap="goToAddress(true)" style="width: 100%; height: 40px;">
                            <div class="d-flex h-100">
                                <div class="m-auto">Указать адрес доставки</div>
                            </div>
                        </DButton>
                    </div>
                    <div v-if="config.public.isDomino" class="mt-2">
                        <DButton @on-tap="goToAddress(false)" :light="true" style="width: 100%; height: 40px;">
                            <div class="d-flex h-100">
                                <div class="m-auto">Забрать из пиццерии</div>
                            </div>
                        </DButton>
                    </div>
                    <div class="bottom">
                        У вас уже есть аккаунт? <NuxtLink to="/auth/login">Войти</NuxtLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.no-address-modal {

    .modal-dialog {
        // max-width: 460px;
    }

    .modal-content {
        background-color: #fff;
        border-radius: 20px;
    }

    .modal-body {
        text-align: center;

        @if not $is-domino {
            background-color: #efc495;
            background-image: url(../images/login-bg.svg);
            background-size: cover;
            border-radius: 20px;
        }

        p {
            margin-top: 20px;
            font-size: 14px;
        }

        a.button {
            font-size: 16px;
            font-weight: bold;
            text-decoration: none;
            color: $primary;
        }

        .title {
            color: #000;

            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .subtitle {
            color: #000;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            margin-top: 5px;
            margin-bottom: 20px;
        }

        .bottom {
            color: #000;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-top: 21px;

            a {
                color: $primary;
            }
        }
    }
}
</style>