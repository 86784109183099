import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_t2GMTTFnMT from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_tbFNToZNim from "/app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_8SbxDRbG6Y from "/app/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import _01_loadToken_gMS0sOBT8N from "/app/plugins/01.loadToken.ts";
import _10_process_qLovjXV3Pa from "/app/plugins/10.process.ts";
import _20_loadData_kSMFdqZpm1 from "/app/plugins/20.loadData.ts";
import _30_insider_client_I2p67HGLZL from "/app/plugins/30.insider.client.ts";
import customScroll_client_yStHPebzCP from "/app/plugins/customScroll.client.ts";
import flip_client_vLSVzSCcun from "/app/plugins/flip.client.ts";
import floating_client_8G5uQNntXG from "/app/plugins/floating.client.ts";
import maska_UHaKf2z1iQ from "/app/plugins/maska.ts";
import masonry_UUne9oxs8w from "/app/plugins/masonry.ts";
import mitt_S0QU5gJPTl from "/app/plugins/mitt.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import useBootstrap_client_ouDJvcVteA from "/app/plugins/useBootstrap.client.ts";
import vCalendar_client_FEmB2rjZFJ from "/app/plugins/vCalendar.client.ts";
import yandexMap_client_ixxceGn7sN from "/app/plugins/yandexMap.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_t2GMTTFnMT,
  plugin_tbFNToZNim,
  plugin_8SbxDRbG6Y,
  _01_loadToken_gMS0sOBT8N,
  _10_process_qLovjXV3Pa,
  _20_loadData_kSMFdqZpm1,
  _30_insider_client_I2p67HGLZL,
  customScroll_client_yStHPebzCP,
  flip_client_vLSVzSCcun,
  floating_client_8G5uQNntXG,
  maska_UHaKf2z1iQ,
  masonry_UUne9oxs8w,
  mitt_S0QU5gJPTl,
  sentry_client_shVUlIjFLk,
  useBootstrap_client_ouDJvcVteA,
  vCalendar_client_FEmB2rjZFJ,
  yandexMap_client_ixxceGn7sN
]